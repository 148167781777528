/* You can add global styles to this file, and also import other style files */
@use 'sass:math';
html, body {
  height: 100%;
}

body {
  margin: 0;
}

@import 'theme';

$castlabs-ui-asset-prefix: '../..' !default;

@import '@castlabs/ui/src/styles/ui';

h3 {
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;
}
h2 {
  font-size: 1.875rem !important;
  margin-bottom: 0 !important;
}
h1 {
  font-size: 2.5rem !important;
  margin-bottom: 0 !important;
  line-height: 52px;
}


.footer-pos {
  height: calc(100% - 45px);
}

.cl-form .invalid-feedback {
  display: unset;
}
